import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { UAParser } from "ua-parser-js";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";
import { v4 as uuidv4 } from 'uuid';

const getBroswerData = () => {
    // const userAgent = window.navigator.userAgent;
    const parser = new UAParser()
    const result = parser.getResult()
    let name = result.browser.name || "Unknown";
    let version = result.browser.version || "";

    // const versionIndex = userAgent.indexOf("Version");
    // if (versionIndex !== -1) {
    //     version = userAgent.substring(versionIndex + 8, versionIndex + 12);
    // } else {
    //     version = userAgent.substring(userAgent.indexOf(name) + name.length + 1);
    // }

    return (`${name}-${version}`)
}
export const AuthenticateUserAPI = createAsyncThunk(
    "authenticateUser/user",
    async (values, { dispatch, getState }) => {
        const parser = new UAParser()
        const result = parser.getResult()

        dispatch(getuserToken())
        let isPasswordResetted = false
        const deviceId = uuidv4();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(
            {
                "UserName": values.username,
                "Password": values.password,
                "q": values.q,
                "DeviceVersion": result.browser.name || "Unknown",
                "DeviceUniqueID": deviceId,
                "DeviceName": result?.browser?.name,
                "DeviceModel": ""
            }
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(Settings.apiUrl + "Patient/Authentication", requestOptions)
            .then(data => data.json())
            .then(json => {
                let password_statu = null;
                if (json?.StatusCode && (json?.StatusCode === 102 || json?.StatusCode === 103 || json?.StatusCode === 104 || json?.StatusCode === 105)) {
                    dispatch(getUserTokenFailed(json?.ResponseMessage))
                }
                else if (json.Status == "unverified") {
                    dispatch(getUserTokenFailed("Token has expired"))
                }
                else if (json == null || json.hasOwnProperty('StatusCode')) {
                    dispatch(getUserTokenFailed(json.ResponseMessage));
                }
                else if (json?.Status == "token verified."
                ) {

                    dispatch(getUserTokenSuccess({ data: json, passwordStatus: false }))
                }

                else if (json[0].hasOwnProperty('resourceType')) {

                    json[0].hasOwnProperty('resourceType') && json[0].extension[1].valueBoolean != undefined && json[0].extension[1].valueBoolean == true ? password_statu = true : password_statu = false

                    // if passwrod staus is true then it will be redirect the user to reset their passwrod.
                    if (password_statu) {
                        // print some thing
                        setPassordResetInfo(json[0].extension[0].valueString, json[0].id, json[0].id)
                        _storeData(json[0].extension[0].valueString, json[0].id, json[0]?.telecom?.map(item => {
                            if (item?.system == "email") {
                                return item?.value
                            }
                        }), 'No', json[0].name[0].text, json[0].extension?.find(o => o.url === "UserName")?.valueString)
                    } else {
                        //alert(json[0].name[0].text)

                        _storeData(json[0].extension[0].valueString, json[0].id, json[0].telecom.map(item => {
                            if (item.system == "email") {
                                return item.value
                            }
                        }), 'No', json[0].name[0].text, json[0].extension?.find(o => o.url === "UserName")?.valueString)

                    }
                    dispatch(getUserTokenSuccess({ data: json, passwordStatus: password_statu }));


                    // sir code ...................
                    // dispatch(getUserTokenSuccess(json));
                }
                else {
                    dispatch(getUserTokenFailed("Login from Inside Api"));
                }
            })
            .catch(err => {
                dispatch(getUserTokenFailed("Some error occured, Please contact practice for further assistance"))

            })

    }
);

export function fetchUserInformationError(errMsg) {
    return (dispatch) => {
        dispatch(getUserTokenFailed(errMsg))
    }
}

export const LogoutUser = createAsyncThunk(
    "logoutUser/user",
    async (values, { dispatch, getState }) => {

        clearStorage();
        getUserId().then((userInfo) => {
            if (userInfo == "none") {
                return
            }
            const item = JSON.parse(userInfo);
            const queryString = '?Token=' + item.token



            var myHeaders = new Headers();
            myHeaders.append("token", item.token);
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {

                method: 'POST',
                headers: myHeaders,

                redirect: 'follow'
            };

            fetch(Settings.apiUrl + "Patient/Logout", requestOptions)
                .then(response => response.json())
                .then(result => {

                    alert(JSON.stringify(result))
                    if (result.StatusCode == 1) {
                        // aslert(result.ResponseMessage)
                    }
                })
                .catch(error => { });

        })
        dispatch(getUserLogout());
    }



)

//clear storage
const clearStorage = async () => {
    try {
        await AsyncStorage.clear()
        //alert('Storage successfully cleared!')
    } catch (e) {
        //alert('Failed to clear the async storage.')
    }
};

const _storeData = async (usertoken, userId, userEmail, isProxy, name, username = "") => {
    try {
        const jsonValue = JSON.stringify({ "token": usertoken, "userid": userId, "email": userEmail, "proxy": isProxy, "username": name, "parentid": userId, "proxyid": userId, "parentname": name, "username": username });
        await AsyncStorage.setItem('@userToken', jsonValue)

    } catch (error) {
        // Error saving data
    }
};

const setPassordResetInfo = async (usertoken, userId) => {
    try {
        const jsonValue = JSON.stringify({ "token": usertoken, "userid": userId });
        await AsyncStorage.setItem('@userPassordReset', jsonValue)

    } catch (error) {
        // Error saving data
    }
};


export const clearUserState = () => {
    return (dispatch) => {
        dispatch(clearUserResetState())
    }
}
const AuthenticateUserSlice = createSlice({
    name: "authenticateUser",
    initialState: {
        isLoggedIn: false,
        isLoggin: false,
        AuthUser: [],
        error: false,
        passworderror: '',
        loginFailed: false,
        userPasswordStatus: false
    },
    reducers: {
        getuserToken: (state, action) => {
            state.isLoggedIn = false
            state.isLoggin = true
            state.AuthUser = []
            state.error = false
            state.passworderror = ""
            state.loginFailed = false
            state.userPasswordStatus = false
        },
        getUserTokenSuccess: (state, action) => {

            state.isLoggedIn = true
            state.isLoggin = false
            state.AuthUser = action.payload.data
            state.error = false
            state.passworderror = ""
            state.loginFailed = false
            state.userPasswordStatus = action.payload.passwordStatus
        },
        getUserTokenFailed: (state, action) => {
            state.isLoggedIn = false
            state.isLoggin = false
            state.AuthUser = []
            state.error = true
            state.passworderror = action.payload
            state.loginFailed = true
            state.userPasswordStatus = false
        },
        clearUserResetState: (state, action) => {

            state.isLoggedIn = false
            state.isLoggin = false
            state.AuthUser = []
            state.error = false
            state.passworderror = ""
            state.loginFailed = false
            state.userPasswordStatus = false
        },
        getUserLogout: (state, action) => {
            state.isLoggedIn = false
            state.isLoggin = false
            state.AuthUser = []
            state.error = false
            state.passworderror = ""
            state.loginFailed = false
            state.userPasswordStatus = false
        },
    },
});


export const {
    getUserLogout,
    getUserTokenSuccess,
    getUserTokenFailed,
    clearUserResetState,
    getuserToken,
} = AuthenticateUserSlice.actions;
export default AuthenticateUserSlice.reducer;
