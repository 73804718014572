import React, { useEffect, useState } from 'react';
import { connect, useSelector, useDispatch } from "react-redux";
import Dots from "react-activity/lib/Dots";
import 'react-activity/lib/Dots/Dots.css';
import Home from "./pages/Home";


import Login from "./pages/Login";
import Signup from "./pages/Signup";
import { AuthContext } from "../shared/core/context";
import { refreshTokenValidatorData, validatePatientTokenFromApi } from "../shared/actions/TokenValidActions";
import getUserId from "../shared/core/GetToken";
import { Modal, Button, Space } from 'antd';
import Appointments from "./pages/Appointments";
import Diagnosis from "./pages/Diagnosis";
import Med from "./pages/Med";
import PatientResults from "./pages/PatientResults";
import AllergiesHome from "./pages/AllergiesHome";
import ImmuneHome from "./pages/ImmuneHome";
import TimelineHome from './pages/TimelineHome';
import RemidersHome from './pages/RemidersHome';
import ResourcesHome from './pages/ResourcesHome';
import ForgetPassword from "./pages/ForgetPassword";

import Vitals from "./pages/Vitals";
import ResetPassword from './pages/ResetPassword';
import UserProfile from "./pages/UserProfile";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import OuterHtml from './components/OutterHtml';
import ProfileScreen from './pages/ProfileScreen';
import Accounts from './pages/Accounts';
import RepeatedMedcation from './pages/RepeatedMedcation';
import ZoomMeeting from './pages/ZoomMeeting';
import Messaging from './pages/Messaging/Messaging';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import { isMobile, isMobileOnly, isAndroid, isIOS } from 'react-device-detect';
import ErrorPage from './pages/ErrorPage';
import { Routes, useParams } from 'react-router-dom';
import { refreshProxyListData } from '../shared/actions/ProxyActions';
import { RefreshApplicationSettings } from '../shared/actions/GetApplicationSettingAction';
import Settings from '../shared/config/Settings';
import LoginSwitch from './pages/LoginSwitch';
import SuccessRoute from './pages/PoliAccountRoutes/SuccessRoute';
import FailRoute from './pages/PoliAccountRoutes/FailRoute';
import CancelRoute from './pages/PoliAccountRoutes/CancelRoute';
import ReactGA from 'react-ga';
import PosMobileComp from './components/PosMobileComp';
import PosStripe from './components/PosStripe';
import PosCancelMobileComp from './components/PosCancelMobileComp';
import PosFailMobileComp from './components/PosFailMobileComp';
import { actionAPI } from '../shared/reduxToolkit/actionAPI';
import PaymentSuccessRoute from './components/PaymentSuccessRoute';
import PaymentFailRoute from './components/PaymentFailRoute';
import PaymentCallback from './components/PaymentCallback';
import PaymentCancel from './components/PaymentCancel';
import LoginDummy from './pages/LoginDummy';
const Route = require("react-router-dom").Route;
const Router = require("react-router-dom").BrowserRouter;

const Redirect = require("react-router-dom").Redirect;
const useNavigate = require("react-router-dom").useNavigate;

const GA_MEASUREMENT_ID = 'UA-266516599-2';
ReactGA.initialize(GA_MEASUREMENT_ID);
//function Main() {
const Main = props => {


    const [userToken, setUserToken] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [mainLoading, setMainLoading] = React.useState(true);
    const [showTimeoutUserModal, setShowTimeoutUserModal] = React.useState(false)
    const [mobDeviceMsgModalVisible, setMobDeviceMsgModalVisible] = useState(false)
    // const { identityApiData, identityApiDataLoading, identityApiDataSuccess } = props.IdentityServerApiData

    const { TokenValidator, isChecking, showModal } = useSelector(state => state.ValidateToken);
    const { Proxy } = useSelector(state => state.ProxyUsers);
    const { ProxyMenu } = useSelector(state => state.ProxyMenu);
    const apiDispatcher = useDispatch()
    const linkHistory = useNavigate();
    useEffect(() => {
        ReactGA.pageview(window.location.pathname);

    }, [window.location.pathname]);

    // useEffect(() => {
    //     console.log("identityApiDataSuccess",identityApiDataSuccess)
    //     if (identityApiDataSuccess && identityApiData.ACCAPIURL) {
    //         Settings.apiUrl = identityApiData.ACCAPIURL
    //     }
    //     else if(localStorage.getItem('ApiBaseUrl'))
    //     {
    //         Settings.apiUrl = localStorage.getItem('ApiBaseUrl');
    //     }
    //     else {
    //         Settings.apiUrl = process.env.REACT_APP_API_URL
    //     }

    //     // if (localStorage.getItem("@userToken")) {

    //     //     props.validateToken(linkHistory);
    //     // }


    // }, [identityApiDataSuccess])
    useEffect(() => {

        apiDispatcher(actionAPI.GetPracticeOrganizationDataAPI())
    }, [])
    useEffect(() => {
        const handleStorageChange = (event) => {
            console.log("storage data changed", event.key)

            if (event.key === "@userToken") {
                if (localStorage.getItem("LoggedTime") !== sessionStorage.getItem("SessionLoggedTime")) {
                    sessionStorage.setItem("SessionLoggedTime", localStorage.getItem("LoggedTime"))

                    window.location.reload()
                }
                if (localStorage.getItem("@userToken")) {

                    apiDispatcher(actionAPI.GetUserProfileAPI())
                    apiDispatcher(actionAPI.GetPracticeOrganizationDataAPI())
                    apiDispatcher(actionAPI.GetProxyMenuAPI())
                }
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [])
    React.useEffect(() => {
        if (!localStorage.getItem("@userToken") && localStorage.getItem("persist:main-root")) {
            apiDispatcher(actionAPI.refreshTokenValidatorData())
            localStorage.clear()
        }
        if (window.location.pathname?.split("/").includes("loginSwitch") || localStorage.getItem("@userPassordReset")) {
            apiDispatcher(actionAPI.refreshTokenValidatorData())

        }
        else {
            if (localStorage.getItem("@userToken") && !JSON.parse(localStorage.getItem("isMFAActive"))) {
                apiDispatcher(actionAPI.ValidateTokenAPI(linkHistory))

            } else {
                localStorage.clear()
            }

        }
        // getUserId().then((data) => {
        //     if (data != 'none') {
        //         props.validateToken(linkHistory);
        //     }
        // })
        console.log("proxy usersssss, =>", Proxy)
        console.log("proxy usersss Menuuuuu, =>", ProxyMenu)
    }, []);
    // useEffect(() => {
    //     const loginTime = localStorage.getItem("LoggedTime")
    //     if (!loginTime) {
    //         linkHistory("/login")
    //         return
    //     }
    //     const currentTime = new Date().getTime()
    //     const elapsedTime = currentTime - loginTime
    //     const timeout = 30 * 60 * 1000
    //     const remainingTime = timeout - elapsedTime

    //     if (remainingTime <= 0) {
    //         setShowTimeoutUserModal(true)
    //     } else {
    //         const timer = setTimeout(() => {
    //             setShowTimeoutUserModal(true)
    //         }, remainingTime)

    //         return () => clearTimeout(timer)
    //     }


    // }, [linkHistory])
    React.useEffect(() => {
        if (!TokenValidator) {
            //alert('hello')
            // if (showModal) {
            //     Modal.warning({
            //         title: 'This is a warning message',
            //         content: 'some messages...some messages...',
            //     });
            // }

            authContext.signOut()
            setMainLoading(false)
        } else {
            setMainLoading(false)
        }
    }, [TokenValidator]);
    //context api
    const authContext = React.useMemo(() => {
        return {
            signIn: () => {
                setUserToken(true);
            },
            signOut: () => {
                setUserToken(false);
                // location.reload();
            },
        }
    })


    const warning = () => {
        Modal.warning({
            title: 'This is a warning message',
            content: 'some messages...some messages...',
        });
    }
    const checkDevice = () => {
        if (isMobileOnly && window.location?.pathname?.toLowerCase()?.indexOf('login') >= 0) {
            setMobDeviceMsgModalVisible(true)
        }
        else {
            setMobDeviceMsgModalVisible(false);
        }
    }
    useEffect(() => {
        checkDevice()
    }, [isMobileOnly])

    // useEffect(() => {
    //     Settings.apiUrl = sessionStorage.getItem("ACCAPIURL") ? sessionStorage.getItem("ACCAPIURL") :process.env.REACT_APP_API_URL;
    // }, [sessionStorage.getItem("ACCAPIURL")])

    return (

        <AuthContext.Provider value={authContext}>



            {
                isChecking && mainLoading &&
                <div className="main-loading-dots">
                    <Dots />
                    </div>

            }
            {
                !isChecking && !mainLoading &&
                // <div>
                //     {!isChecking && TokenValidator ? <Header /> : null}
                //     <div id="myphonemenu" className={!isChecking && TokenValidator ? "page-body" : null}>
                //         {!isChecking && TokenValidator ? <Sidebar /> : null}
                //         <div className={!isChecking && TokenValidator ? "page-content-wrapper" : null}>
                <Routes>

                        <Route element={<PublicRoute authed={TokenValidator} />}>

                            <Route path="/login" caseSensitive element={<Login />} />
                            <Route path="/loginSwitch/:q" caseSensitive element={<LoginSwitch />} />
                            <Route path="/Account/RegistrationIndici" caseSensitive element={<Signup />} />
                            <Route path="/forgetPassword" caseSensitive element={<ForgetPassword />} />
                            <Route path="/resetpassword" caseSensitive element={<ResetPassword />} />

                        </Route>

                        <Route element={<PrivateRoute authed={TokenValidator} />}>

                            <Route caseSensitive path="/preview" element={<OuterHtml />} />



                            <Route caseSensitive path="/" element={<Home />} />
                            <Route caseSensitive path="/appointments" element={<Appointments />} />
                            {/* <Route caseSensitive path="/zoom" element={<ZoomMeeting />} /> */}
                            <Route caseSensitive path="/diagnosis" element={<Diagnosis />} />


                            <Route caseSensitive path="/medications" element={<Med />} />
                            <Route caseSensitive path="/reports" element={<PatientResults />} />
                            <Route caseSensitive path="/allergies" element={<AllergiesHome />} />
                            <Route caseSensitive path="/immunisation" element={<ImmuneHome />} />
                            <Route caseSensitive path="/timeline" element={<TimelineHome />} />
                            <Route caseSensitive path="/reminders" element={<RemidersHome />} />
                            <Route caseSensitive path="/resources" element={<ResourcesHome />} />
                            {/* <Route caseSensitive path="/letters" element={<LettersDocs />} /> */}
                            <Route caseSensitive path="/vitals" element={<Vitals />} />
                            <Route caseSensitive path="/emails" element={<Messaging />} />
                            <Route caseSensitive path="/profile" element={<ProfileScreen />} />
                            <Route caseSensitive path="/profile-old" element={<UserProfile />} />
                            {/* <Route caseSensitive path="/medication" element={<RepeatedMedcation />} /> */}
                            <Route caseSensitive path="/accounts" element={<Accounts />} />




                        </Route>


                        <Route caseSensitive path="/pos" element={<PosMobileComp />} />
                        <Route caseSensitive path="/poss" element={<PosStripe />} />
                        <Route caseSensitive path="/posCancel" element={<PosCancelMobileComp />} />
                        <Route caseSensitive path="/posFail" element={<PosFailMobileComp />} />
                        <Route caseSensitive path="/success" element={<SuccessRoute />} />
                        <Route caseSensitive path="/fail" element={<FailRoute />} />
                        <Route caseSensitive path="/cancel" element={<CancelRoute />} />


                        <Route caseSensitive path="/paymentsuccess" element={<PaymentSuccessRoute />} />
                        <Route caseSensitive path="/paymentfail" element={<PaymentFailRoute />} />
                        <Route caseSensitive path="/paymentcallback" element={<PaymentCallback />} />
                        <Route caseSensitive path="/paymentcancel" element={<PaymentCancel />} />
                    <Route path="*" element={<ErrorPage />} />

                </Routes>
                //             {!isChecking && <footer className="footer-ignore">
                //                 <small>Version 0.4.7.5<br />© Copyrights . All rights Reserved</small>
                //             </footer>}
                //         </div>

                //     </div>

                // </div >



            }

            {/* {
                isChecking &&
                <View className="main-loading-dots">
                    <Dots />
                </View>

            }
            {
                !isChecking && userToken != null || TokenValidator ? (
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/appointments" component={Appointments} />
                        <Route exact path="/diagnosis" component={Diagnosis} />
                        <Route exact path="/medications" component={Med} />
                        <Route exact path="/reports" component={PatientResults} />
                        <Route exact path="/allergies" component={AllergiesHome} />
                        <Route exact path="/immunisation" component={ImmuneHome} />
                        <Route exact path="/timeline" component={TimelineHome} />
                        <Route exact path="/reminders" component={RemidersHome} />
                        <Route exact path="/resources" component={ResourcesHome} />
                        <Route exact path="/letters" component={LettersDocs} />
                        <Route exact path="/vitals" component={Vitals} />
                        <Route exact path="/emails" component={Messaing} />
                        <Route exact path="/profile" component={UserProfile} />

                    </Switch>
                ) : (
                        <Switch>
                            <Route exact path="/" component={Login} />
                            <Route exact path="/forgetPassword" component={ForgetPassword} />
                            <Route exact path="/resetpassword" component={ResetPassword} />
                        </Switch>
                    )
            } */}

            {/* <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/appointments" component={Appointments} /> 
            </Switch> */}
            <Modal
                open={mobDeviceMsgModalVisible}
                onOk={() => {
                    setMobDeviceMsgModalVisible(false);
                    // window.open("https://play.google.com/store/apps/details?id=nz.app.myindici", "_blank")
                    // if (isIOS) {
                    //     window.location = "https://apps.apple.com/us/app/myindici-2-0/id1577040594"

                    // } else {
                    //     window.location = "https://play.google.com/store/apps/details?id=nz.app.myindici"
                    // }
                }}
                closable={false}
                onCancel={() => setMobDeviceMsgModalVisible(false)}
                cancelText="Close"
                okButtonProps={{ style: { display: 'none' } }}
                title="Switch to Mobile App"
            >
                {/* For the best user experience, we recommend you install the mobile app. The app also enables you to take advantage of your device's inbuilt features such as Fingerprint or Face ID login, touch controls, push notifications and enhancements.
                <p>Tap to visit
                    {isAndroid ? <span className='text-primary ml-2 text-underline' onClick={() => window.location = "https://play.google.com/store/apps/details?id=nz.app.myindici"}>Android App</span> : <span className='text-primary ml-2 text-underline' onClick={() => window.location = "https://apps.apple.com/us/app/myindici-2-0/id1577040594"}>IOS App</span>}
                </p> */}
                {isIOS && <p className='m-0'>If you are using an iOS device (iPhone or iPad), we recommend installing the  <b>myindici 2.0 app</b> for the best experience.
                    <br /> <br />Here are the steps:
                    <ol className='ml-3'>
                        <li className='mt-2'>

                            Open the <b>App Store</b> on your iOS device.
                        </li>
                        <li className='mt-2'>

                            Search for <b>"myindici 2.0"</b> in the App Store.
                        </li>
                        <li className='mt-2'>

                            <b>Download and install</b> the myindici 2.0 app from the search results.
                        </li>
                    </ol>
                </p>}
                {isAndroid && <p className='m-0'>If you are using an Android device, we recommend installing the <b>myindici 2.0 app</b> for the best experience. <br /><br />Here's how to do it: <br />
                    <ol className='ml-3'>
                        <li className='mt-2'>
                            Open the <b>Google Play Store</b> on your Android device.
                        </li>
                        <li className='mt-2'>
                            Search for <b>"myindici 2.0"</b> in the Play Store.
                        </li>
                        <li className='mt-2'>
                            <b>Download and install</b> the myindici 2.0 app from the search results.
                        </li>
                    </ol>
                </p>}
            </Modal>
            <Modal
                open={showTimeoutUserModal}
                title="Session Timedout"
                onOk={() => {
                    setShowTimeoutUserModal(false)

                    apiDispatcher(actionAPI.LogoutUser())
                    apiDispatcher(actionAPI.refreshTokenValidatorData())
                    localStorage.clear()
                    linkHistory("/login")
                }}
                maskClosable={false}
                closable={false}
                cancelButtonProps={{ style: { display: "none" } }}
            >
                <p>Your Session has timedout. Click OK to logout.</p>
            </Modal>
        </AuthContext.Provider >
    );
}




function mapStateToProps(state) {
    return {
        TokenValidator: state.TokenValidator,
        IdentityServerApiData: state.IdentityServerReducer,

    }
}
function mapDispatchToProps(disptach) {
    return {
        validateToken: (history) => disptach(validatePatientTokenFromApi(history)),

        validateTokenRefresh: () => disptach(refreshTokenValidatorData()),
        proxyListRefresh: () => disptach(refreshProxyListData()),
        appSetttingsRefresh: () => disptach(RefreshApplicationSettings()),

    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Main)
